import React, { useState } from 'react';
import { Input, Row, Col, Modal } from 'antd';

const SensorManager = ({ activeSensors, onSetSensorPrefs }) => {
  const [temporaryValues, setTemporaryValues] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentSensorKey, setCurrentSensorKey] = useState('');
  const [currentField, setCurrentField] = useState('');
  const [inputValue, setInputValue] = useState('');

  const handleIntervalClick = (sensorKey, field) => {
    setCurrentSensorKey(sensorKey);
    setCurrentField(field);
    // Show the poll_interval divided by 1000
    setInputValue(activeSensors[sensorKey][field] / 1000);
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    const sanitizedValue = parseFloat(inputValue);
    if (!isNaN(sanitizedValue)) {
      // Multiply the input value by 1000 before updating
      const updatedValue = sanitizedValue * 1000;
      let updatedConfig = { ...activeSensors };
      updatedConfig[currentSensorKey] = {
        ...updatedConfig[currentSensorKey],
        [currentField]: updatedValue
      };
      setTemporaryValues(prev => ({
        ...prev,
        [currentSensorKey]: {
          ...prev[currentSensorKey],
          [currentField]: updatedValue
        }
      }));
      onSetSensorPrefs(updatedConfig);
      setIsModalVisible(false);
    } else {
      alert("Invalid input: not a number");
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <h2 className="welcome-heading">Sensors</h2>
      {activeSensors && Object.keys(activeSensors).length > 0 && activeSensors.device ? (
        <>
          <Row gutter={24} style={{ marginBottom: '5px' }}>
            <Col xs={9} lg={6}><h4>Device</h4></Col>
            <Col xs={9} lg={9}><h4>Measurement</h4></Col>
            <Col xs={6} lg={9}><h4>Interval</h4></Col>
          </Row>

          {Object.entries(activeSensors)
            .filter(([key]) => key.startsWith('sensor'))
            .map(([sensorKey, sensor]) => (
              <Row key={sensorKey} gutter={24} style={{ marginBottom: '5px' }}>
                <Col xs={9} lg={6}>{sensor.device}</Col>
                <Col xs={9} lg={9}>{sensor.measurement}</Col>
                <Col xs={6} lg={9} onClick={() => handleIntervalClick(sensorKey, 'poll_interval')}>
                <span style={{ cursor: 'pointer', color: '#1890ff' }}>
                  {(temporaryValues[sensorKey]?.poll_interval || sensor.poll_interval) / 1000} second
                </span>
                </Col>
              </Row>
            ))}
        </>
      ) : (
        <p>No sensors available in range of this hub...</p> // Fallback message if there are no valid sensors
      )}

      <Modal
        title="Edit Interval"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onPressEnter={handleModalOk}
        />
      </Modal>
    </div>
  );
};

export default SensorManager;
