import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './ResponsiveLineChart.css';

const ResponsiveLineChart = ({ data, isLoading, renamedKeys, sensorConfig, onConfigUpdate }) => {
    const chartContainerRef = useRef(null);
    const [chartWidth, setChartWidth] = useState(0);
    const [sensorKeys, setSensorKeys] = useState([]);
    const [sensorColors, setSensorColors] = useState({});
    const [axisData, setAxisData] = useState({});
    const [renderChart, setRenderChart] = useState(false);
    const [visibility, setVisibility] = useState({});

    const [isAutoscaleEnabled, setIsAutoscaleEnabled] = useState(false);
    const [customAxisLimits, setCustomAxisLimits] = useState({});

    const [minTimestamp, setMinTimestamp] = useState(0);
    const [maxTimestamp, setMaxTimestamp] = useState(0);
    const [xAxisDomain, setXAxisDomain] = useState([0, 0]);

    const colorPalette = [
        '#007bfe', // Dark Blue
        '#32b3e0', // Light Blue
        '#5bc0be', // Bolder Tiffany Blue
        '#febd72', // Yellow  
        '#fd875a', // Orange
        '#fe4b35', // Red
        '#e743a4', // Purple
        '#c872a7', // Lavender
    ];
      
    const formatTimestamp = (timestamp) => {
        // Legacy timestamp support
        if (typeof timestamp === 'number') {
            timestamp = new Date(timestamp);
        }

        const pad = (num) => num.toString().padStart(2, '0');
  
        const month = pad(timestamp.getMonth() + 1); // Months are zero-indexed
        const day = pad(timestamp.getDate());
        const hour = pad(timestamp.getHours());
        const minute = pad(timestamp.getMinutes());
        const second = pad(timestamp.getSeconds());
      
        return `${month}/${day} ${hour}:${minute}:${second}`;
    };

    const parseTimestampString = (rawTimestamp) => {
        // Legacy timestamp support
        if (typeof rawTimestamp === 'string') {
            const year = parseInt(rawTimestamp.slice(0, 4), 10);
            const month = parseInt(rawTimestamp.slice(4, 6), 10) - 1; // Months are zero-indexed in JavaScript
            const day = parseInt(rawTimestamp.slice(6, 8), 10);
            const hour = parseInt(rawTimestamp.slice(8, 10), 10);
            const minute = parseInt(rawTimestamp.slice(10, 12), 10);
            const second = parseInt(rawTimestamp.slice(12, 14), 10);
            
            return new Date(year, month, day, hour, minute, second);
        } else {
            if (typeof rawTimestamp === 'number') {
                return rawTimestamp;
            }
        }
    };

    const flattenedData = useMemo(() => {
        const documentsMap = new Map();
    
        if (data && data.length > 0) {
            data.forEach(doc => {
                let newDataArray;
                if (!doc.data || doc.data.length === 0) {
                    newDataArray = [];
                } else {
                    newDataArray = doc.data.map(item => ({
                        ...item,
                        timestamp: parseTimestampString(item.timestamp)
                    }));
                }
    
                if (documentsMap.has(doc.document)) {
                    const existingDocument = documentsMap.get(doc.document);
                    existingDocument.data = newDataArray;
                    documentsMap.set(doc.document, existingDocument);
                } else {
                    documentsMap.set(doc.document, { ...doc, data: newDataArray });
                }
            });
    
            // Flatten the documents into a single array by extracting and merging the 'data' arrays from each document
            const flattenedData = Array.from(documentsMap.values()).flatMap(doc => doc.data);
    
            // Determine the bucket size based on the length of flattened data
            let bucketSize;
            if (flattenedData.length >= 1000000) {
                bucketSize = 100;
            } else if (flattenedData.length >= 100000) {
                bucketSize = 10;
            } else {
                bucketSize = 1; // No downsampling if the length is less than 100,000
            }
    
            // Downsample the data
            const downsampledData = [];
            
            for (let i = 0; i < flattenedData.length; i += bucketSize) {
                const bucket = flattenedData.slice(i, i + bucketSize);
                const averageDataPoint = bucket.reduce((acc, item, index) => {
                    Object.keys(item).forEach(key => {
                        if (typeof item[key] === 'number') {
                            acc[key] = (acc[key] || 0) + item[key] / bucket.length;
                        } else if (key === 'timestamp' && index === bucket.length - 1) {
                            acc[key] = item[key]; // Assign the timestamp from the last entry in the bucket
                        } else {
                            acc[key] = item[key]; // Non-numeric values (e.g., other fields) can be taken from any item in the bucket
                        }
                    });
                    return acc;
                }, {});
                downsampledData.push(averageDataPoint);
            }
    
            return downsampledData;
        } else {
            return [];
        }
    }, [data]);

    useEffect(() => {
        if (flattenedData && flattenedData.length > 0) {
            const timestamps = flattenedData.map(doc => doc.timestamp);
            const minTimestamp = Math.min(...timestamps);
            const maxTimestamp = Math.max(...timestamps);

            setMinTimestamp(minTimestamp);
            setMaxTimestamp(maxTimestamp);
            setXAxisDomain([minTimestamp, maxTimestamp]);
        };
    }, [flattenedData]);
    
    const processedData = useMemo(() => {
        if (flattenedData && flattenedData.length > 0) {
            // Filter data based on xAxisDomain
            const filteredData = flattenedData.filter(item => {
                // Assuming item.timestamp is already in a comparable format (e.g., UNIX timestamp)
                return item.timestamp >= xAxisDomain[0] && item.timestamp <= xAxisDomain[1];
            });
    
            // Apply formatTimestamp function to all item.timestamp in filtered array
            const formattedData = filteredData.map(item => ({
                ...item,
                timestamp: formatTimestamp(item.timestamp)
            }));

            return formattedData;
        } else {
            return [];
        }
    }, [flattenedData, xAxisDomain]);

    const setSensorKeysAndColors = useCallback(() => {
        setRenderChart(false);

        if (!sensorConfig || typeof sensorConfig !== 'object') {
            console.warn('Sensor configuration is missing or invalid');
            return;
        }

        const sensorColorsMap = {};
        const sensorKeysSet = new Set();
        let sensorIndex = 0;

        // TO-DO: need to handle gracefully cases where there is no config. Right now this crashes
        Object.keys(sensorConfig).forEach(key => {
            if (key.startsWith('sensor') && !sensorKeysSet.has(key)) {
                sensorKeysSet.add(key);
                sensorColorsMap[key] = colorPalette[sensorIndex % colorPalette.length]; // Assign color from palette
                sensorIndex++; // Increment sensorIndex for each new sensor key
            }
        });
    
        setSensorKeys(Array.from(sensorKeysSet));
        setSensorColors(sensorColorsMap);
        const initialVisibility = {};
        sensorKeysSet.forEach(key => {
            initialVisibility[key] = true; // Initially, all series are visible
        });
    setVisibility(initialVisibility);
    console.log("Triggered setSensorKeysandColors")
    }, [sensorConfig, colorPalette]); 

    // Effect to find sensor keys and assign colors
    useEffect(() => {
        if (sensorConfig !== undefined) {
            setSensorKeysAndColors();
        }
    }, [sensorConfig]);

    useEffect(() => {
        const updateSize = () => {
            if (chartContainerRef.current) {
                const newWidth = chartContainerRef.current.getBoundingClientRect().width;
                setChartWidth(newWidth);
            }
        };

        window.addEventListener('resize', updateSize);
        updateSize();

        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const handleLegendClick = (dataKey) => {
        const currentName = renamedKeys[dataKey] || axisData[dataKey]?.name || dataKey;
        console.log(axisData, axisData[dataKey], dataKey, renamedKeys[dataKey], axisData[dataKey]?.name, currentName);
        
        const maxNameLength = 50;
        const newName = window.prompt(`Enter a new name for ${currentName} sensor:`, currentName);

        const sanitizeInput = (input) => {
            const sanitized = input.replace(/[^a-zA-Z0-9 _-]/g, '');
            return sanitized.length > maxNameLength ? sanitized.substring(0, maxNameLength) : sanitized;
        };
    
        if (newName && newName.trim() !== '' && newName !== currentName) {
            const sanitizedNewName = sanitizeInput(newName.trim());
    
            if (sanitizedNewName !== '') {
                let updatedConfig = {};
                updatedConfig[dataKey] = {};
                updatedConfig[dataKey]['name'] = sanitizedNewName;
                console.log("Chart formatted new data like this:");
                console.log(updatedConfig);
                onConfigUpdate(updatedConfig);
            } else {
                alert('Please choose a name using only letters, numbers, dashes, underscores and spaces.');
            }
        }
    };
    
    const handleAxisVisToggle = (sensorKey) => {
        setVisibility(prevVisibility => ({
            ...prevVisibility,
            [sensorKey]: !prevVisibility[sensorKey]
        }));
        console.log("Toggled visibility for ", sensorKey)
    };

    // Determine if any sensors (and hence any YAxes) are visible
    const anyAxesVisible = Object.values(visibility).some(vis => vis);

    // Adjust the left margin based on whether any axes are visible
    // If no axes are visible, increase the left margin to prevent x-axis label overflow
    const chartMargin = {
        top: 5,
        right: 5,
        left: anyAxesVisible ? 5 : 65, // Adjust this value as needed to prevent overflow
        bottom: 10,
    };

    const renderLegend = (props) => {
        // This payload should represent all sensors, even if they are not currently visible.
        const payload = sensorKeys.map(key => ({
            value: renamedKeys[key] || key,
            color: sensorColors[key],
            type: 'line', // or the corresponding type of your chart
        }));
    
        if (!processedData || processedData.length === 0) {
            return <div style={{ width: "100%", height: '40px'}}></div>;
        }
        
        return (
            <div className="legend-container" style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                {
                    payload.map((entry, index) => (
                        <div key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginLeft: '10px', marginRight: '10px' }}>
                            <input
                                type="checkbox"
                                checked={visibility[sensorKeys[index]]}
                                onChange={() => handleAxisVisToggle(sensorKeys[index])}
                                style={{ marginRight: '5px' }}
                            />
                            <div style={{ width: '20px', height: '2px', backgroundColor: entry.color, marginRight: '5px' }}></div>
                            <span onClick={() => handleLegendClick(sensorKeys[index], entry.value)} style={{ color: entry.color }}>
                                {renamedKeys[sensorKeys[index]] || (sensorConfig && sensorConfig[sensorKeys[index]] && sensorConfig[sensorKeys[index]].name ? sensorConfig[sensorKeys[index]].name : entry.value)}
                            </span>
                        </div>
                    ))
                }
            </div>
        );
    };

    // Create axis for each sensor
    useEffect(() => {
        const aggregateAxisData = (keys, config, colors) => {
            let axisDataMap = {};
            let newConfig = {};
            let defaultsUsed = false;
        
            keys.forEach((sensorKey, index) => {
                // Load sensor config
                let name, measurement, unit, min, max;
        
                if (config && config[sensorKey]) {
                    name = config[sensorKey].name || sensorKey;
                    measurement = config[sensorKey].measurement || '';
                    unit = config[sensorKey].unit || '';
                    min = config[sensorKey].min !== undefined ? config[sensorKey].min : null;
                    max = config[sensorKey].max !== undefined ? config[sensorKey].max : null;
                }

                let identifier = `${measurement} (${unit})`;

                axisDataMap[sensorKey] = {
                    sensor: sensorKey,
                    name: name,
                    yAxisId: identifier,
                    measurement: measurement,
                    color: colors[sensorKey],
                    unit: unit,
                    min: min,
                    max: max
                };
                console.log("Read config from database for: ", sensorKey);
                console.log(axisDataMap[sensorKey]);
                
                // Check if autoscale is enabled to override config, or if custom limits were set
                if (isAutoscaleEnabled) {
                    console.log ("Autoscale is on")
                    const dataPoints = processedData.map(item => item[sensorKey]).filter(v => v !== undefined);
                    const minValue = Math.min(...dataPoints);
                    const maxValue = Math.max(...dataPoints);
                    // Add 5% padding
                    const padding = (maxValue - minValue) * 0.05;
                    axisDataMap[sensorKey]['min'] = Math.floor((minValue - padding) * 10) / 10;
                    axisDataMap[sensorKey]['max'] = Math.ceil((maxValue + padding) * 10) / 10;
                } else {
                    const customLimits = customAxisLimits
                    if (customLimits[sensorKey] && customLimits[sensorKey]['min'] !== undefined) {
                        console.log(`Custom min limit detected: ${customLimits[sensorKey]['min']}`)
                        axisDataMap[sensorKey]['min'] = customLimits[sensorKey]['min'];
                        if (customLimits[sensorKey]['min'] !== axisData[sensorKey]['min']) {
                            let updatedConfig = {}
                            updatedConfig[sensorKey] = {}
                            updatedConfig[sensorKey]['min'] = axisDataMap[sensorKey]['min']  
                            onConfigUpdate(updatedConfig);
                        }
                    }
                    if (customLimits[sensorKey] && customLimits[sensorKey]['max'] !== undefined) {
                        console.log(`Custom min limit detected: ${customLimits[sensorKey]['max']}`)
                        axisDataMap[sensorKey]['max'] = customLimits[sensorKey]['max'];
                        if (customLimits[sensorKey]['max'] !== axisData[sensorKey]['max']) {
                            let updatedConfig = {}
                            updatedConfig[sensorKey] = {}
                            updatedConfig[sensorKey]['max'] = axisDataMap[sensorKey]['max']  
                            onConfigUpdate(updatedConfig);
                        }
                    }
                }
            });
            if (defaultsUsed) onConfigUpdate(newConfig);
            setAxisData(axisDataMap);
            setRenderChart(true);
        };
        if (sensorKeys.length>0) {
            aggregateAxisData(sensorKeys, sensorConfig, sensorColors);
        }
    },[sensorKeys, sensorConfig, sensorColors, isAutoscaleEnabled, customAxisLimits])

    useEffect (() => {
        console.log("Sensor config updated: ", sensorConfig);
    },[sensorConfig])

    const CustomYAxisTick = ({ x, y, payload, sensorKey, onTickClick }) => {
        const handleClick = () => {
          onTickClick(payload.value, sensorKey);
        };

        const formattedValue = isAutoscaleEnabled ? payload.value.toFixed(0) : (customAxisLimits[sensorKey] ? payload.value.toFixed(0) : payload.value.toFixed(0));

        return (
          <text
            x={x}
            y={y}
            fill="#666"
            textAnchor="end"
            onClick={handleClick}
            style={{ cursor: 'pointer' }}
          >
            {formattedValue}
          </text>
        );
      };
      
      const handleTickClick = (value, sensorKey) => {
        console.log("Clicked tick: ", value)
        let isMin = false;
        let isMax = false;
        isMin = value === axisData[sensorKey].min;
        if (isMin) console.log(`${value} is min`)
        isMax = value === axisData[sensorKey].max;
        if (isMax) console.log(`${value} is max`)

        if (isMin || isMax) {
            const newValue = window.prompt(
                `Enter new ${isMin ? 'minimum' : 'maximum'} value for ${axisData[sensorKey].yAxisId}:`,
                value
            );
    
            const sanitizeInput = (input) => {
                const sanitized = input.replace(/[^0-9.\-]/g, ''); // Allow only numbers, periods, and dashes
                return sanitized.length > 10 ? sanitized.substring(0, 10) : sanitized;
            };
    
            if (newValue && newValue.trim() !== '') {
                const sanitizedValue = sanitizeInput(newValue);
                const parsedValue = parseFloat(sanitizedValue);
    
                if (!isNaN(parsedValue)) {
                    // Valid number, round and update the state and turn off autoscale
                    const setValue = Math.round(parsedValue);
                    setCustomAxisLimits(prevLimits => ({
                        ...prevLimits,
                        [sensorKey]: {
                            ...prevLimits[sensorKey],
                            [isMin ? 'min' : 'max']: setValue
                        }
                    }));
    
                    if (isAutoscaleEnabled) {
                        setIsAutoscaleEnabled(false);
                    }
                } else {
                    alert('Please enter a number up to 10 characters long using digits, periods, and the minus sign.');
                }
            } else {
                alert('Please enter a number up to 10 characters long using digits, periods, and the minus sign.');
            }
        }
    };

    return (
        <div>
            <div ref={chartContainerRef} style={{ width: '100%', height: '350px' }}>
                { isLoading ? (
                    <div className="loader-container">
                        <div className="loader"></div>
                    </div>
                ) : processedData.length > 0 && renderChart ? (
                    chartWidth > 0 && (
                        <LineChart
                            width={chartWidth}
                            height={330}
                            data={processedData}
                            margin={chartMargin}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis 
                                dataKey="timestamp"
                                angle={-45} 
                                tick={{ dy: 32, dx: -30 }} 
                                height={75}
                                />
                            {Object.entries(axisData).map(([sensorKey, config]) => (
                                visibility[sensorKey] && ( // Only render YAxis if the sensor is visible
                                    <YAxis
                                        allowDataOverflow={true}
                                        key={config.yAxisId}
                                        yAxisId={config.yAxisId || 'default'} // Use 'default' as a fallback yAxisId
                                        label={{ value: `${config.measurement}, ${config.unit}`, angle: -90, position: 'insideLeft', offset: 16 }}
                                        stroke="grey"
                                        domain={[config.min, config.max]}
                                        tick={<CustomYAxisTick sensorKey={sensorKey} onTickClick={handleTickClick} />}
                                    />
                                )
                            ))}
                            <Legend 
                                verticalAlign="top" 
                                align="center" 
                                wrapperStyle={{ 
                                    top: 0, 
                                    width: '100%', 
                                    paddingBottom: '10px',
                                }}
                                content={renderLegend} />
                            {sensorKeys.map(sensorKey => (
                                visibility[sensorKey] && (
                                    <Line
                                    key={sensorKey}
                                    type="monotone"
                                    connectNulls
                                    dataKey={sensorKey}
                                    name={renamedKeys[sensorKey] || sensorKey}
                                    stroke={sensorColors[sensorKey]}
                                    yAxisId={axisData[sensorKey].yAxisId}
                                    isAnimationActive={false}
                                    dot={false}
                                    />
                                )
                            ))}
                            <Tooltip/>
                        </LineChart>
                    )
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        This session is empty.
                    </div>
                )}
            </div>
            <div className="chart-controls">
                <label className="switch">
                <input
                    type="checkbox"
                    checked={isAutoscaleEnabled}
                    onChange={(e) => setIsAutoscaleEnabled(e.target.checked)} />
                <span className="slider round"></span>
                </label>
                <span className="toggle-label">Autoscale</span>
                <div className="range-picker-container">
                    <Slider
                        range
                        min={minTimestamp}
                        max={maxTimestamp}
                        value={xAxisDomain}
                        onChange={(newRange) => {
                            console.log('New xAxisDomain set:', newRange);
                            setXAxisDomain(newRange);
                        }}
                        // Make sure to disable the slider if data is not loaded
                        disabled={(minTimestamp === 0 && maxTimestamp === 0) || isLoading}
                    />
                </div>
            </div>
        </div>
    );
};    

export default ResponsiveLineChart;