import React from 'react';
import { Timeline } from 'antd';
import moment from 'moment';
import './SessionTimeline.css';

const SessionTimeline = ({ sessions, onSessionSelect, activeSession, shouldGlow, hubLoadComplete }) => {
  // Define a function to create the custom dot for the first index if shouldGlow is true
  const getCustomDot = (index) => {
    if (index === 0 && shouldGlow) {
      // Custom glowing dot for the first item
      return (
        <div
          className="red-glow"
          style={{
            height: '10px',
            width: '10px',
            borderRadius: '50%',
            backgroundColor: 'red',
          }}
        ></div>
      );
    }
    // For the first item without glow or the rest of the items, return null to use default dot
    return null;
  };

  if (hubLoadComplete && (!sessions || sessions.length === 0)) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        There are no sessions for this hub. To create a new session, connect your hub to the Internet and press the plus icon above, or simply press start.
      </div>
    );
  }

  return (
    <Timeline>
      {sessions && sessions.length > 0 ? (
        sessions.map((session, index) => {
          const isActiveSession = session === activeSession;
          
          return (
            <Timeline.Item
              key={session}
              dot={getCustomDot(index)} // Get the custom dot only if shouldGlow is true for the first item
              color="blue" // Set the color for inactive dots
              onClick={() => onSessionSelect(session)}
              style={{
                marginLeft: '10px',
                marginTop: '10px'
              }}
            >
              <div
                style={{
                  cursor: 'pointer',
                  border: isActiveSession ? '2px solid grey' : 'none',
                  borderRadius: isActiveSession ? '5px' : '0',
                  boxShadow: isActiveSession ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '',
                  marginTop: isActiveSession ? '-4px' : '2px',
                  padding: isActiveSession ? '3px' : '0',
                  paddingRight: isActiveSession ? '10px' : '0',
                  display: 'inline-block',
                }}
              >
                {typeof session === 'string' 
                  ? moment(session, 'YYYYMMDDHHmmSS').format('MM/DD/YYYY HH:mm:SS') //  Legacy timestamp support
                  : moment(session).format('MM/DD/YYYY HH:mm:SS')}
              </div>
            </Timeline.Item>
          );
        })
      ) : (
        <p>Loading...</p>
      )}
    </Timeline>
  );
};

export default SessionTimeline;